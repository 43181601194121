export const routes = [
    {
        path: '/tax_rates',
        name: 'tax_rates.browse',
        component: () => import(/* webpackChunkName: "TaxRatesBrowse" */ '@/views/app/TaxRatesBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/tax_rates/create',
        name: 'tax_rates.create',
        component: () => import(/* webpackChunkName: "TaxRatesActions" */ '@/views/app/TaxRatesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/tax_rates/:id/edit',
        name: 'tax_rates.edit',
        component: () => import(/* webpackChunkName: "TaxRatesActions" */ '@/views/app/TaxRatesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/tax_rates/:id/delete',
        name: 'tax_rates.delete',
        component: () => import(/* webpackChunkName: "TaxRatesActions" */ '@/views/app/TaxRatesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]